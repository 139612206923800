<template>
  <v-container fluid>
    <v-btn icon class="ml-5 mt-4 mb-n6" to="/email_code">
      <v-icon>mdi-chevron-left </v-icon> email
    </v-btn>
    <div class="Password">
      <div justify="center" align="center">
        <h1 class="text-center text-h4 mt-10">Recuperar Contraseña</h1>
        <br />
        <v-img
          lazy-src="../assets/Logo_DPEI.png"
          max-height="155"
          max-width="325"
          src="../assets/Logo_DPEI.png"
        ></v-img>
        <br />
      </div>
      <div class="text-center" justify="center">
        <v-dialog v-model="dialog" width="350">
          <v-card class="text-center">
            <v-card-title class="text-h4 grey justify-center">
              Invalido
            </v-card-title>

            <v-card-text class="text-h6">
              Codigo o Email incorrectos
            </v-card-text>
            <v-card-actions>
              <v-btn
                style="margin-left: auto; margin-right: auto"
                color="error"
                outlined
                text
                @click="dialog = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-row style="height: 400px" justify="center" align="center">
        <v-col cols="md-5">
          <v-form v-model="valid">
            <v-text-field
              v-model="code"
              label="Codigo de recuperación"
              outlined
              :rules="reglas_code"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="E-mail"
              outlined
              :rules="reglas_email"
              required
            ></v-text-field>
            <v-row class="mx-0">
              <v-text-field
                v-model="password"
                label="Nueva Contraseña"
                outlined
                required
                :rules="reglas_pass"
                :type="show_pass ? '' : 'password'"
              ></v-text-field>
              <v-btn
                icon
                class="mt-3 ml-n11"
                @mousedown="show_pass = true"
                @mouseup="show_pass = false"
                v-touch="{
                  start: () => (show_pass = true),
                  end: () => (show_pass = false),
                }"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </v-row>
            <v-row class="mx-0">
              <v-text-field
                @keydown.enter="check()"
                v-model="new_password"
                label="Confirmar Contraseña"
                outlined
                :rules="reglas_pass"
                required
                :type="show_pass ? '' : 'password'"
              ></v-text-field>
              <v-btn
                icon
                class="mt-3 ml-n11"
                @mousedown="show_pass = true"
                @mouseup="show_pass = false"
                v-touch="{
                  start: () => (show_pass = true),
                  end: () => (show_pass = false),
                }"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </v-row>
            <div justify="center" align="center">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4 mt-2"
                v-on:click="check"
                >Enviar
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
/* eslint-disable */
import axios from "axios";
export default {
  name: "Password",
  data: () => ({
    valid: true,
    dialog: false,
    code: "",
    show_pass: false,
    password: "",
    new_password: "",
    email: "",
    reglas_email: [(v) => !!v || "Email es requerido"],
    reglas_code: [(v) => !!v || "Recovery code is required"],
    reglas_pass: [(v) => !!v || "Contraseñas no son iguales!"],
    url: process.env.VUE_APP_ROOT_URL + "user/recover-password",
  }),
  methods: {
    check() {
      if (this.password == this.new_password) {
        this.recover();
      } else {
        this.password = "";
        this.new_password = "";
        return;
      }
    },
    recover() {
      let vueInstance = this;
      this.email = this.email.toLowerCase();
      this.email = this.email.replace(/\s+/g, "");
      axios({
        method: "POST",
        url: this.url,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: this.email,
          new_password: this.password,
          recovery_code: this.code,
        },
      })
        .then(function(response) {
          window.location.replace("login");
        })
        .catch(function(error) {
          console.log(error);
          vueInstance.code = "";
          vueInstance.email = "";
          vueInstance.dialog = true;
        });
    },
  },
};
</script>
